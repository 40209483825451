import { useCookie } from './useCookie';

const UTM_FIELDS_KEY = 'utmFields';

type UTMObject = { utmSource: string; utmCampaign: string; utmMedium: string };

export const useUTMFields = () => {
  const { cookie } = useCookie(UTM_FIELDS_KEY);

  const { utmSource, utmCampaign, utmMedium } = JSON.parse(cookie ?? '{}') as UTMObject;

  return { utmSource, utmMedium, utmCampaign };
};
