import { location } from '~src/util';

export const LEAD_ORIGINS = {
  EDC: 'edc',
  NORDEA: 'nordea',
  NORLYS: 'norlys',
  JYSKE_BANK: 'jyskebank',
  INSTALLATOR_GRUPPEN: 'installatorgruppen',
};

export const useLeadOrigin = (): { leadOrigin: string | undefined } => {
  const { href } = location ?? {};

  const leadOrigin = Object.values(LEAD_ORIGINS).find(origin => href?.includes(`/${origin}`));

  return { leadOrigin: leadOrigin ?? 'bodil' };
};
